define("workflows-web/workflows/mixins/happyfox/filter-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    requestService: Ember.inject.service('request'),
    intlService: Ember.inject.service('intl'),
    createBasicFilterFields: function createBasicFilterFields() {
      var intlService = this.get('intlService');
      var fieldsArray = Ember.A();
      var statusField = Ember.Object.create({
        name: 'Status',
        type: 'dynamic',
        key: 'status_id',
        predicates: ['in', 'not_in'],
        actionOnOpen: this.fetchResults,
        onSearch: this.fetchResults,
        isLoading: this.fetchResults.isRunning,
        entity: 'status',
        groupName: 'HappyFox Statuses',
        allowMultipleValues: true
      });
      var statusBehaviorField = Ember.Object.create({
        name: intlService.findTranslationByKey('status-behavior'),
        type: 'local-single-autocomplete',
        options: [{
          value: 'pending',
          label: intlService.findTranslationByKey('pending')
        }, {
          value: 'completed',
          label: intlService.findTranslationByKey('completed')
        }],
        key: 'status_behavior',
        predicates: ['equals', 'not_equals']
      });
      var priorityField = Ember.Object.create({
        name: 'Priority',
        type: 'dynamic',
        key: 'priority_id',
        predicates: ['in', 'not_in'],
        onSearch: this.fetchResults,
        actionOnOpen: this.fetchResults,
        isLoading: this.fetchResults.isRunning,
        entity: 'priority',
        groupName: 'HappyFox Priorities',
        allowMultipleValues: true
      });
      var assigneeField = Ember.Object.create({
        name: intlService.findTranslationByKey('assignee'),
        type: 'dynamic',
        key: 'assignee_id',
        predicates: ['in', 'not_in', 'set', 'not_set'],
        onSearch: this.fetchResults,
        actionOnOpen: this.fetchResults,
        isLoading: this.fetchResults.isRunning,
        entity: 'staff',
        groupName: 'HappyFox Agents',
        allowMultipleValues: true
      });
      var categoryField = Ember.Object.create({
        name: intlService.findTranslationByKey('category'),
        type: 'dynamic',
        key: 'category_id',
        predicates: ['in', 'not_in'],
        onSearch: this.fetchResults,
        actionOnOpen: this.fetchResults,
        isLoading: this.fetchResults.isRunning,
        entity: 'category',
        groupName: 'HappyFox Categories',
        allowMultipleValues: true
      });
      var contactField = Ember.Object.create({
        name: intlService.findTranslationByKey('contact-caps'),
        type: 'dynamic',
        key: 'contact_id',
        predicates: ['in', 'not_in'],
        actionOnOpen: this.fetchResults,
        allowMultipleValues: true
      });

      // let contactGroupField = EmberObject.create({
      //   name: intlService.findTranslationByKey('contact-group'),
      //   type: 'remote-multiple-autocomplete',
      //   lookUpUrl: CONTACT_GROUPS_AUTOCOMPLETE_URL,
      //   key: 'contact_group_id',
      //   predicates: ['in', 'not_in', 'set', 'not_set']
      // });

      // let primaryContactGroupField = EmberObject.create({
      //   name: intlService.findTranslationByKey('primary-contact-group'),
      //   type: 'remote-multiple-autocomplete',
      //   lookUpUrl: CONTACT_GROUPS_AUTOCOMPLETE_URL,
      //   key: 'primary_contact_group_id',
      //   predicates: ['in', 'not_in', 'set', 'not_set']
      // });

      var dueDateField = Ember.Object.create({
        name: intlService.findTranslationByKey('due-date'),
        type: 'local-single-autocomplete',
        options: [{
          value: 'yesterday',
          label: intlService.findTranslationByKey('yesterday')
        }, {
          value: 'today',
          label: intlService.findTranslationByKey('today')
        }, {
          value: 'tomorrow',
          label: intlService.findTranslationByKey('tomorrow')
        }, {
          value: 'next_seven_days',
          label: intlService.findTranslationByKey('next_seven_days')
        }, {
          value: 'on',
          label: intlService.findTranslationByKey('on')
        }, {
          value: 'between',
          label: intlService.findTranslationByKey('between')
        }],
        key: 'due_date',
        predicates: ['equals', 'not_equals', 'set', 'not_set']
      });
      var tagsField = Ember.Object.create({
        name: intlService.findTranslationByKey('tags'),
        type: 'dynamic',
        key: 'tags',
        predicates: ['in', 'not_in', 'set', 'not_set'],
        onSearch: this.fetchResults,
        actionOnOpen: this.fetchResults,
        isLoading: this.fetchResults.isRunning,
        entity: 'tags',
        groupName: 'HappyFox Ticket Tags',
        allowMultipleValues: true
      });
      var ticketCustomFieldsField = Ember.Object.create({
        name: intlService.findTranslationByKey('ticket-custom-fields'),
        type: 'dynamic',
        key: 'custom_fields',
        lookUpUrl: 'entity-autocomplete/happyfox/ticket-custom-fields/'
      });
      var unrespondedField = Ember.Object.create({
        name: intlService.findTranslationByKey('unresponded'),
        key: 'unresponded'
      });
      var subscribedField = Ember.Object.create({
        name: intlService.findTranslationByKey('subscribed'),
        key: 'subscribers'
      });
      var hasAttachmentsField = Ember.Object.create({
        name: intlService.findTranslationByKey('has-attachments'),
        key: 'has_attachments'
      });
      var slaBreachedField = Ember.Object.create({
        name: intlService.findTranslationByKey('sla-breached'),
        key: 'has_breached_sla'
      });
      var privateTicketField = Ember.Object.create({
        name: intlService.findTranslationByKey('ticket-visibility'),
        type: 'local-single-autocomplete',
        options: [{
          value: true,
          label: intlService.findTranslationByKey('private')
        }, {
          value: false,
          label: intlService.findTranslationByKey('public')
        }],
        key: 'is_private',
        predicates: ['equals', 'not_equals']
      });
      var messagesCountField = Ember.Object.create({
        name: intlService.findTranslationByKey('no-of-messages'),
        type: 'dynamic',
        predicates: ['equals', 'not_equals', 'lt', 'lte', 'gt', 'gte'],
        key: 'messages_count'
      });

      // let ticketIdField = EmberObject.create({
      //   name: intlService.findTranslationByKey('ticket-id'),
      //   type: 'remote-multiple-autocomplete',
      //   lookUpUrl: TICKETS_AUTOCOMPLETE_URL,
      //   key: 'id',
      //   predicates: ['in']
      // });

      var createdAtField = Ember.Object.create({
        name: intlService.findTranslationByKey('created'),
        type: 'timestamp',
        key: 'created_at',
        isTimeStampField: true,
        predicates: ['on', 'lt', 'lte', 'gt', 'gte', 'between']
      });
      var lastStaffReplyField = Ember.Object.create({
        name: intlService.findTranslationByKey('last-staff-reply'),
        type: 'timestamp',
        key: 'last_staff_reply_at',
        isTimeStampField: true,
        predicates: ['on', 'lt', 'lte', 'gt', 'gte', 'between']
      });
      var lastContactReplyField = Ember.Object.create({
        name: intlService.findTranslationByKey('last-contact-reply'),
        type: 'timestamp',
        key: 'last_contact_reply_at',
        isTimeStampField: true,
        predicates: ['on', 'lt', 'lte', 'gt', 'gte', 'between']
      });
      var lastUpdatedAtField = Ember.Object.create({
        name: intlService.findTranslationByKey('last-updated-at'),
        type: 'timestamp',
        key: 'last_updated_at',
        isTimeStampField: true,
        predicates: ['on', 'lt', 'lte', 'gt', 'gte', 'between']
      });
      var lastModifiedAtField = Ember.Object.create({
        name: intlService.findTranslationByKey('last-modified-at'),
        type: 'timestamp',
        key: 'last_modified_at',
        isTimeStampField: true,
        predicates: ['on', 'lt', 'lte', 'gt', 'gte', 'between']
      });

      // let repliedField = EmberObject.create({
      //   name: intlService.findTranslationByKey('staff-reply'),
      //   type: 'local-multiple-autocomplete',
      //   options: this.get('dataService').peekActiveStaff(),
      //   key: 'staff_reply',
      //   predicates: ['by', 'not_by']
      // });

      // let privateNoteAddedField = EmberObject.create({
      //   name: intlService.findTranslationByKey('private-note-added'),
      //   type: 'local-multiple-autocomplete',
      //   options: this.get('dataService').peekActiveStaff(),
      //   key: 'private_note_added',
      //   predicates: ['by', 'not_by']
      // });

      // let participatedField = EmberObject.create({
      //   name: intlService.findTranslationByKey('participated'),
      //   type: 'local-multiple-autocomplete',
      //   options: this.get('dataService').peekActiveStaff(),
      //   key: 'participated',
      //   predicates: ['by', 'not_by']
      // });

      // let unrespondedSinceField = EmberObject.create({
      //   name: intlService.findTranslationByKey('unresponded-since'),
      //   type: 'timestamp',
      //   key: 'unresponded_since',
      //   predicates: ['gte']
      // });

      // let ticketSourceField = EmberObject.create({
      //   name: intlService.findTranslationByKey('ticket-source'),
      //   type: 'local-multiple-autocomplete',
      //   options: [
      //     { id: 'support_center', name: intlService.findTranslationByKey('ticket-source.support-center') },
      //     { id: 'contact_form', name: intlService.findTranslationByKey('ticket-source.contact-form') },
      //     { id: 'facebook', name: intlService.findTranslationByKey('ticket-source.facebook') },
      //     { id: 'twitter', name: intlService.findTranslationByKey('ticket-source.twitter') },
      //     { id: 'phone', name: intlService.findTranslationByKey('ticket-source.phone') },
      //     { id: 'chat', name: intlService.findTranslationByKey('ticket-source.chat') },
      //     { id: 'email', name: intlService.findTranslationByKey('ticket-source.email') },
      //     { id: 'admin_panel', name: intlService.findTranslationByKey('ticket-source.admin-panel') },
      //     { id: 'api', name: intlService.findTranslationByKey('ticket-source.api') },
      //     { id: 'scheduled_ticket', name: intlService.findTranslationByKey('ticket-source.scheduled-ticket') },
      //     { id: 'past_channels', name: intlService.findTranslationByKey('ticket-source.past-channels') }
      //   ],
      //   key: 'source',
      //   predicates: ['in', 'not_in']
      // });

      // let agentScriptField = EmberObject.create({
      //   name: intlService.findTranslationByKey('ticket-details.add-update.agent-script'),
      //   type: 'remote-multiple-autocomplete',
      //   lookUpUrl: AGENT_SCRIPT_AUTOCOMPLETE_URL,
      //   key: 'agent_scripts',
      //   predicates: ['in', 'not_in', 'set', 'not_set']
      // });

      var subjectField = Ember.Object.create({
        name: intlService.findTranslationByKey('subject'),
        type: 'dynamic',
        key: 'subject',
        predicates: ['contains', 'does_not_contain']
      });
      var ticketIdField = Ember.Object.create({
        name: intlService.findTranslationByKey('ticket-id'),
        type: 'dynamic',
        key: 'id',
        predicates: ['in'],
        allowMultipleValues: true
      });
      fieldsArray.addObjects([statusField, statusBehaviorField, priorityField, assigneeField, categoryField, dueDateField, tagsField, unrespondedField, subscribedField, hasAttachmentsField, slaBreachedField, ticketCustomFieldsField, privateTicketField, messagesCountField, createdAtField, lastContactReplyField, lastModifiedAtField, lastStaffReplyField, lastUpdatedAtField, contactField, subjectField, ticketIdField]);
      return fieldsArray;
    }
  });
});