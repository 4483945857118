define("workflows-web/workflows/details/edit/actions/action/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yGYNqmo8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"hf-workflows_action_order\"],[15,5,[34,10]],[12],[1,[34,11]],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[30,[36,12],[\"hf-workflows-action_selected-item-container\",[30,[36,5],[[35,4,[\"isActionStepSaved\"]],\"hf-workflows-actions-grid\"],null]],null]],[12],[2,\"\\n\"],[6,[37,5],[[35,6,[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],[[35,9]],[[\"extra\",\"option\",\"select\"],[[30,[36,8],[[35,4]],null],[30,[36,8],[[35,6,[\"selected\"]]],null],[30,[36,8],[[35,6]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"ember-power-select-selected-item\"],[12],[18,1,[[35,6,[\"selected\"]],[35,6]]],[13],[2,\"\\n\"],[6,[37,7],[[35,4,[\"isActionDropdownDisabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"hf-u-push-right hf-workflows-action_selected-item-container_status-indicator\"],[12],[2,\"\\n          \"],[1,[30,[36,3],[[30,[36,5],[[35,4,[\"doHighlight\"]],\"dropdown-caret-white\",\"dropdown-caret-dark\"],null]],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[35,1]],[[\"placeholder\"],[[35,0]]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hf-u-flex-container hf-u-push-right hf-u-icon-right-margin hf-mod-extra\"],[14,\"data-test-id\",\"hf-builder-step-action-trigger\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[\"right-arrow\"],[[\"class\"],[\"hf-u-rotate\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"placeholder\",\"placeholderComponent\",\"component\",\"inline-svg\",\"extra\",\"if\",\"select\",\"unless\",\"readonly\",\"selectedItemComponent\",\"color\",\"displayOrder\",\"concat-strings\"]}",
    "meta": {
      "moduleName": "workflows-web/workflows/details/edit/actions/action/trigger/template.hbs"
    }
  });
});