define("workflows-web/components/dynamic-field/input-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Gh/WTUeO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"hf-u-position-relative\"],[12],[2,\"\\n  \"],[1,[30,[36,6],null,[[\"options\",\"value\",\"valueUpdated\"],[[30,[36,5],null,[[\"lineNumbers\",\"lineWrapping\",\"mode\",\"readOnly\",\"smartIndent\",\"styleActiveLine\",\"autofocus\"],[false,true,[35,4],[35,3],true,true,true]]],[35,2],[30,[36,1],[[32,0],\"onValueChange\"],null]]]]],[2,\"\\n  \"],[11,\"div\"],[24,0,\"hf-dynamic-field-dropdown-trigger\"],[16,\"data-test-id\",[30,[36,9],[\"hf-dynamic-field-dropdown-trigger-\",[30,[36,8],[[35,7,[\"key\"]]],null]],null]],[4,[38,1],[[32,0],\"toggleDropdown\"],[[\"bubbles\"],[false]]],[12],[1,[30,[36,10],[\"dropdown-dark-bg-arrow-down\"],null]],[13],[2,\"\\n\"],[6,[37,12],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hf-dynamic-field_input-placeholder\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"placeholderText\",\"action\",\"fieldValue\",\"readOnly\",\"mode\",\"hash\",\"ivy-codemirror\",\"field\",\"convert-to-test-id\",\"concat\",\"inline-svg\",\"showPlaceholder\",\"if\"]}",
    "meta": {
      "moduleName": "workflows-web/components/dynamic-field/input-component/template.hbs"
    }
  });
});