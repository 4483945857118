define("workflows-web/workflows/apps/details/custom-app/component", ["exports", "workflows-web/workflows/apps/details/manage-app-form/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    getRawFieldsInfo: function getRawFieldsInfo() {
      return this.app.fields.map(function (field) {
        var _field = {};
        _field.label = field.label || field.name;
        _field.key = field.name.camelize();
        _field.isMandatory = field.isRequired;
        _field.alwaysIncludeInPayload = true;
        if (field.formFieldType === 'password') {
          _field.isPassword = true;
        } else {
          _field.isText = true;
        }
        return Ember.Object.create(_field);
      });
    }
  });
});