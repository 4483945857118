define("workflows-web/workflows/apps/details/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LOGOS_WITH_NO_HEIGHT = ['bamboohr'];
  var _default = _exports.default = Ember.Controller.extend({
    app: Ember.computed.reads('model'),
    router: Ember.inject.service(),
    accountInfoService: Ember.inject.service('account-info'),
    isUserSelfServed: Ember.computed.reads('accountInfoService.isUserSelfServed'),
    appType: Ember.computed.reads('router.currentRoute.queryParams.app_type'),
    workflowsAppsController: Ember.inject.controller('workflows.apps'),
    appsInfo: Ember.computed.reads('workflowsAppsController.appsInfo'),
    hasBillingPermission: Ember.computed.reads('accountInfoService.hasBillingPermission'),
    requestService: Ember.inject.service('request'),
    toastMessageService: Ember.inject.service('toast-message'),
    intlService: Ember.inject.service('intl'),
    applicationName: Ember.computed('appsInfo', 'app', {
      get: function get() {
        var _this$appsInfo,
          _this = this;
        var appsList = ((_this$appsInfo = this.appsInfo) === null || _this$appsInfo === void 0 ? void 0 : _this$appsInfo.apps) || [];
        var app = appsList.find(function (app) {
          return app.name === _this.app.application;
        });
        return app ? app.displayName : '';
      }
    }),
    categoryList: Ember.computed.reads('workflowsAppsController.categoryList'),
    appLogosWithNoHeight: LOGOS_WITH_NO_HEIGHT,
    shouldRemoveHeightFromLogo: Ember.computed('app', {
      get: function get() {
        return this.get('appLogosWithNoHeight').includes(this.get('app.application'));
      }
    }),
    showWarningBanner: Ember.computed('app.{availableInPlan,availableToInstall,isInstalled}', 'accountInfoService.isUserSelfServed', {
      get: function get() {
        return this.accountInfoService.isUserSelfServed && !this.app.isInstalled && (this.app.availableInPlan === false || this.app.availableToInstall === false);
      }
    }),
    // Integration Type will be shown for Premium and Enterprise apps
    showIntegrationTypeChip: Ember.computed('app.integrationType', 'accountInfoService.isUserSelfServed', {
      get: function get() {
        return this.accountInfoService.isUserSelfServed && (this.app.integrationType === 'premium' || this.app.integrationType === 'enterprise');
      }
    }),
    actions: {
      goToSelectedAppCategory: function goToSelectedAppCategory() {
        this.router.transitionTo('workflows.apps', {
          queryParams: {
            app_type: this.get('appType')
          }
        });
      },
      redirectToBilling: function redirectToBilling() {
        var _this2 = this;
        var newBillingPage = window.open('', '_blank');
        newBillingPage.document.write(this.intlService.findTranslationByKey('billing.redirecting'));
        this.requestService.fetchBillingLink().then(function (response) {
          var redirectUrl = response.redirectUrl;
          newBillingPage.location.href = redirectUrl;
        }).catch(function () {
          newBillingPage.close();
          _this2.toastMessageService.showMessage({
            type: 'failure',
            text: 'billing.error-redirecting-message'
          });
        });
      }
    }
  });
});