define("workflows-web/components/form-field-input/text/component", ["exports", "workflows-web/components/form-field-input/base", "workflows-web/components/form-field-input/mixins/autofocus"], function (_exports, _base, _autofocus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend(_autofocus.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('field.autoFocus')) {
        this.autoFocus();
      }
    },
    attributeBindings: ['dataTestId:data-test-id'],
    dataTestId: Ember.computed('field.key', {
      get: function get() {
        return "hf-form-field-text-input-".concat(this.field.key);
      }
    }),
    actions: {
      onFocusOut: function onFocusOut() {
        this.get('field').validate();
      },
      onEnter: function onEnter() {
        if (this.onEnter) {
          this.onEnter();
        }
      },
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value);
        }
      }
    }
  });
});